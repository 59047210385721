<template>
  <div>
    <AlertError :error="error" />
    <label for="to">Invia a</label>
    <select
      id="to"
      class="form-select mb-4"
      multiple aria-label="multiple select example"
      style="height: 50vh"
    >
      <option
        v-for="user in users"
        :key="user.id"
        :value="user.email"
        @click="onUserClick(user, $event)"
      >
        {{ user.name + ' ' + user.surname}}
      </option>
    </select>

    <label for="communication">La comunicazione</label>
    <select
      id="communication"
      class="form-select form-select-sm"
      aria-label=".form-select-sm example"
      v-model="selectedCommunication"
    >
      <option selected disabled>Seleziona Comunicazione</option>
      <option
        v-for="communication in communications"
        :key="communication.id"
        :value="communication.id"
      >
        {{ communication.title }}
      </option>

    </select>

    <button v-if="!isLoading" class="btn bg-blue mt-4" @click="onSend">
      Invia
    </button>
    <div class="mt-4" v-if="isLoading && clicked">
      <SpinnerBlue :loading="isLoading" />
    </div>
  </div>
</template>

<script>
import { allUsers } from "@/api/users.api";
import { sendCommunication, showAllCommunications } from "@/api/communication.api";
import AlertError from "@/components/alerts/AlertError";
import { spinnerIsLoading } from "@/mixins/spinner.mixin";
import SpinnerBlue from "@/components/spinners/SpinnerBlue";
import { sweetAlertSuccess } from "@/utilities/sweetalert";

export default {
  name: "CommunicationSend",
  components: {SpinnerBlue, AlertError},
  mixins: [spinnerIsLoading],
  data() {
    return {
      users: [],
      selected: [],
      communications: [],
      selectedCommunication: '',
      error: '',
      clicked: false,
    }
  },
  methods: {
    async onSend() {
      this.error = '';
      this.clicked = true;
      this.startLoading();

      if (this.selected.length < 1) {
        return this.error = 'Nessun utente selezionato';
      }

      if (!this.selectedCommunication) {
        return this.error = 'Nessuna comunicazione selezionata';
      }

      const response = await sendCommunication(this.selected, this.selectedCommunication);
      this.stopLoading();
      this.clicked = false;

      if (response.status) {
        await sweetAlertSuccess('success', 'Comunicazione inviata con successo!');
      } else {
        await sweetAlertSuccess('error', 'Si è verificato un errore.');
      }

    },
    onUserClick(user, event) {
      const { email } = user;

      if (event.ctrlKey) {
        this.addOrRemove(email);
      } else {
        if (this.selected.length > 1) {
          this.resetSelectedUser();
          this.selected.push(email);
        } else {
          this.addOrRemove(email);
        }
      }

    },
    resetSelectedUser() {
      this.selected = [];
    },
    addOrRemove(email) {
      if (!email) return;

      if (this.selected.includes(email)) {
        const index = this.selected.indexOf(email);
        index > -1 && this.selected.splice(index, 1);
      } else {
        this.selected.push(email);
      }
    },
    async getCommunications()
    {
      const response = await showAllCommunications();
      this.communications = response.data;
    },
    async getUsers() {
      const response = await allUsers();
      this.users = response.data;
    }
  },
  mounted() {
    this.getUsers();
    this.getCommunications();
  }
}
</script>

<style scoped>

</style>